import React,{useState,useEffect} from "react";
import axios from "axios";
import moment from "moment";

export default function home(props) {
	useEffect(()=>{
    //getbonjourrec();
    //callbonjourrec();
    })

  const [reglimit,setreglimit]=useState("")
  const [regskip,setregskip]=useState("") 
  const [referal,setreferal]=useState("") 
  const [load,setload]  = useState(false)
  const [relimit,setrelimit]  = useState(0)
  const [reskip,setreskip]  = useState(0)
  const [buylimit,setbuylimit]  = useState("")
  const [buyload,setbuyload]  = useState(false)
  const [pid,setpoolid]= useState(0)
  const [reload,setreload]= useState(false)
  const [customload,setcustomload]= useState(false)
  const [searchaddress,setsearchaddress]= useState("")
  const [investamt,setinvestamt]= useState(0)
  const [reinvestamt,setreinvestamt]= useState(0)
  const [claimlimit,setclaimlimit]= useState(0)
  const [claimskip,setclaimskip]= useState(0)
  const [userlist,setuserlist]= useState([])
  let baseurl = "https://mlmscriptapi.wealwin.com/"
  //let baseurl = "http://localhost:3534/"

  async function join(){
    if(parseFloat(investamt)>0 ){
      setload(true)
      let data = {
        limit : reglimit,
        skip: regskip,
        referreraddress : referal,
        "investamt":investamt,
        "pid": pid
      }
      var response = await axios.post(baseurl+"amb_join", data);
      alert(response.data.message)
      setload(false)
    }else{
      alert("Invalid Amount")
    }
  }

  async function rejoin(){
    if(parseFloat(reinvestamt)>0){
      setreload(true)
      let data = {
        limit : relimit,
        skip: reskip,
        "investamt":reinvestamt,
        "pid": pid
      }
      var response = await axios.post(baseurl+"amb_rejoin", data);
      alert(response.data.message)
      setreload(false)
    }else{
      alert("Invalid Amount")
    }
  }

  async function claim(){
    setcustomload(true)
    let data = {
      "pid": pid,
      limit : claimlimit,
        skip: claimskip,
    }
    var response = await axios.post(baseurl+"amb_claim", data);
     alert(response.data.message)
    setcustomload(false)
  }

  async function search_details(){
    let data = {
      "address": searchaddress,
      "pid": pid,
    }
    var response = await axios.post(baseurl+"amb_search", data);
    if(response && response.data && response.data.data){
      setuserlist(response.data.data)
    }else{
      setuserlist([])
    }
     
  }


  return (
    <div className="header_home">
    
     <h3><center>Ambasse Join</center></h3><br></br>
     <form>
        <center>
       <label for="fname">Limit:
       <input type="text" id="limit" name="limit" onChange={(e)=>setreglimit(e.target.value)} /><br></br>
       </label><br></br>
       <label for="lname">Skip:
       <input type="text" id="skip" name="skip" onChange={(e)=>setregskip(e.target.value)}/><br></br>
       </label><br></br>
       <label for="lname">Referal Address:
       <input type="text" id="referal" name="referal" onChange={(e)=>setreferal(e.target.value)}/><br></br>
       </label><br></br>
       <label for="lname">Invest Amount:
       <input type="text" id="investamt" name="investamt" onChange={(e)=>setinvestamt(e.target.value)}/><br></br>
       </label><br></br>
       <label for="lname">Pool Id:
       <input type="text" id="poolid" name="poolid" onChange={(e)=>setpoolid(e.target.value)}/><br></br>
       </label><br></br>
       <input type="button" value={load ?"Processing...":"Submit"} onClick={load ? "":()=>join()}/><br></br>
      </center>
    </form>
    <h2>------------------------------------------------------------------------------------------------------------------------------------</h2>
    <h3><center> Re-Join</center></h3><br></br>
     <form>
        <center>
       <label for="fname">Limit:
       <input type="text" id="relimit" name="relimit" onChange={(e)=>setrelimit(e.target.value)} /><br></br>
       </label><br></br>
       <label for="lname">Skip:
       <input type="text" id="reskip" name="reskip" onChange={(e)=>setreskip(e.target.value)}/><br></br>
       </label><br></br>
       <label for="lname">Re-Invest Amount:
       <input type="text" id="reinvestamt" name="reinvestamt" onChange={(e)=>setreinvestamt(e.target.value)}/><br></br>
       </label><br></br>
       <label for="lname">Pool Id:
       <input type="text" id="poolid" name="poolid" onChange={(e)=>setpoolid(e.target.value)}/><br></br>
       </label><br></br>
       <input type="button" value={reload ?"Processing...":"Submit"} onClick={reload ? "":()=>rejoin()}/><br></br>
      </center>
    </form>
    <h2>------------------------------------------------------------------------------------------------------------------------------------</h2>
    <h3><center>Claim</center></h3><br></br>
      <form>
        <center>
       <label for="lname">Pool Id:
       <input type="text" id="poolid" name="poolid" onChange={(e)=>setpoolid(e.target.value)}/><br></br>
       </label><br></br>
        <label for="fname">Limit:
        <input type="text" id="limit" name="limit" onChange={(e)=>setclaimlimit(e.target.value)}/><br></br>
        </label><br></br>
        <label for="lname">Skip:
        <input type="text" id="skip" name="skip" onChange={(e)=>setclaimskip(e.target.value)}/><br></br>
        </label><br></br>
        <input type="button" value={customload?"Processing..":"Submit"} onClick={customload ? "":()=>claim()}/><br></br>
      </center>
    </form>
     <h2>------------------------------------------------------------------------------------------------------------------------------------</h2>
     
     <form>
       <center>
       <label for="lname">Search by address:
       <input type="text" id="address" name="address" onChange={(e)=>setsearchaddress(e.target.value)}/><br></br>
       </label><br></br>
       <label for="lname">Pool Id:
       <input type="text" id="poolid" name="poolid" onChange={(e)=>setpoolid(e.target.value)}/><br></br>
       </label><br></br>
        <input type="button" value="Search" onClick={()=>search_details()}/><br></br>
        <br></br>
       <label for="lname">{userlist && userlist[0] ? userlist[0] : "" }
       </label><br></br>
       <label for="lname">{userlist && userlist[1] ? userlist[1] : ""}
       </label><br></br>
      </center>
    </form>
    </div>
  );
}







