import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Oldhome from "./views/ambassedor"

function App() {
  return (
    <BrowserRouter>
      <div className="container mt-2" style={{ marginTop: 40 }}>
        <Routes>
        <Route path='/home' element={<Oldhome/>} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
